import AppCore from "../components/ui/NnpcAppRoot";
import AppLogin from "./AdminLogin";
import { Routes, Route } from 'react-router-dom';

function NNPCDataGrabApp() {
    return (
        <Routes>
            <Route path="/" element={<AppLogin/>}/>
            <Route path="/dashboard" element={<AppCore/>}/>
        </Routes>
    );

}

export default NNPCDataGrabApp;