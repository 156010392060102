import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    gasHeaterHTBEntries: [
        {
            timeStamp: '',
            time: '',
            water_level: '',
            water_temp: '',
            fuel_gas_pressure: '',
            fuel_gas_temp: '',
        },
    ],
    gasHeaterHTBAverage: {},
}
const gasHeaterHTBSlice = createSlice({
    name: 'gasHeaterHTAEntries',
    initialState: initialState,
    reducers: {
        addHeaterHTBData: (state, {payload}) => {
            const {water_level, time, timeStamp, water_temp, fuel_gas_pressure, fuel_gas_temp} = payload
            state.gasHeaterHTBEntries.push({
                timeStamp: timeStamp,
                time: time,
                water_level: water_level,
                water_temp: water_temp,
                fuel_gas_pressure: fuel_gas_pressure,
                fuel_gas_temp: fuel_gas_temp,
            })
        },
        updateHeaterHTBAverage(state, {payload}) {
            const {water_level, water_temp, fuel_gas_pressure, fuel_gas_temp} = payload
            state.gasHeaterHTBAverage = {
                water_level: water_level,
                water_temp: water_temp,
                fuel_gas_pressure: fuel_gas_pressure,
                fuel_gas_temp: fuel_gas_temp,
            }
        },
    },
})

export const {addHeaterHTBData, updateHeaterHTBAverage} = gasHeaterHTBSlice.actions

export default gasHeaterHTBSlice.reducer
