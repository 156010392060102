import { Tab } from "semantic-ui-react";

import { InletAverage } from "./averages-remarks-section/InletAverage";
import { OutletAverages } from "./averages-remarks-section/OutletAverages";
import { HeaterA } from "./equipment-status/heaters/HeaterA";
import { HeaterB } from "./equipment-status/heaters/HeaterB";
import { MTRL402A } from "./equipment-status/metering-skid/MTRL-402A";
import { MTRL402B } from "./equipment-status/metering-skid/MTRL-402B";
import { LINEA } from "./equipment-status/pressure-reduction-skid/LINE-A";
import { LINEB } from "./equipment-status/pressure-reduction-skid/LINE-B";
import { FS201A } from "./equipment-status/separator/FS-201A";
import { FS201B } from "./equipment-status/separator/FS-201B";
import OutageStart from "./equipment-status/power-outage/Start";
import OutageEnd from "./equipment-status/power-outage/End";

export const HeaterContent = () => {
  const equipmentStatusHeatersPanes = [
    { menuItem: "HEATER-A", render: () => <HeaterA /> },
    { menuItem: "HEATER-B", render: () => <HeaterB /> },
  ];
  return <Tab panes={equipmentStatusHeatersPanes} />;
};

export const MeteringSkidContent = () => {
  const equipmentStatusMeteringSkidPanes = [
    { menuItem: "MTRL-402A", render: () => <MTRL402A /> },
    { menuItem: "MTRL-402B", render: () => <MTRL402B /> },
  ];

  return <Tab panes={equipmentStatusMeteringSkidPanes} />;
};

export const PressureReduSkidContent = () => {
  const equipmentStatusPressureReduSkidPanes = [
    { menuItem: "LINE-A", render: () => <LINEA /> },
    { menuItem: "LINE-B", render: () => <LINEB /> },
  ];

  return <Tab panes={equipmentStatusPressureReduSkidPanes} />;
};

export const SeparatorContent = () => {
  const equipmentStatusSeparatorPanes = [
    { menuItem: "FS-201A", render: () => <FS201A /> },
    { menuItem: "FS-201B", render: () => <FS201B /> },
  ];

  return <Tab panes={equipmentStatusSeparatorPanes} />;
};

export const PowerOutageContent = () => {
  const equipmentStatusPowerOutPanes = [
    { menuItem: "START", render: () => <OutageStart /> },
    { menuItem: "END", render: () => <OutageEnd /> },
  ];

  return <Tab panes={equipmentStatusPowerOutPanes} />;
};
