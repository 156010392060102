import { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";

export function AppInput({
  placeholder,
  onChanged,
  inputType,
  field,
  formRef,
}) {
  const [input, setInput] = useState("");
  const [isError, setIsError] = useState(false);

  const handleInput = (e) => {
    onChanged(e.target.value);
    setInput(e.target.value);
    setIsError(false); // Reset error when the input changes
  };

  const clearInput = () => {
    setInput("");
    setIsError(false); // Reset error when the input is cleared
  };

  useEffect(() => {
    // Use the formRef to expose clearInput function to the form component
    if (formRef) {
      formRef.current[field] = {
        clearInput,
      };
    }
  }, [formRef, field]);

  //   const isError = input === "";

  return (
    <FormControl isInvalid={isError}>
      <Input
        placeholder={placeholder}
        width="auto"
        value={input}
        onChange={handleInput}
        type={inputType}
      />
      {!isError ? (
        <FormHelperText></FormHelperText>
      ) : (
        <FormErrorMessage>{placeholder} is required</FormErrorMessage>
      )}
    </FormControl>
  );
}
