import React from "react";
import {Heading, Box, AbsoluteCenter, Divider} from '@chakra-ui/react'

export  function AppHeading({headerTitle, dividerLabel}) {
    return (
        <>
            <Heading as='h2' size='lg'>{headerTitle}</Heading>
            <Box position='relative' padding='10'>
                <Divider/>
                <AbsoluteCenter bg='white' px='4'>
                    {dividerLabel}
                </AbsoluteCenter>
            </Box>
        </>
    );
}
