import React, {useEffect, useRef, useMemo,useState} from "react";
import { Icon, Label, Menu, Tab, Table } from "semantic-ui-react";
import {
  Table as Tbl,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  TableCaption,
  AbsoluteCenter,
  Box,
  VStack,
  TableContainer,
  useToast,
} from "@chakra-ui/react";
import {
  AppInput,
  AppHeading,
  TimeSelector,
  AppButton,
} from "../forms/AppForm";
import { useSelector, useDispatch } from "react-redux";
import {recordTimeFrames} from "../../utils/constants";
import {calculateAverageEntry} from "../../utils/helpers";
import {addInstGasSkidData, updateInstGasSkidAverage} from "../../store/reducers/instrumentGasSkidReducer";

export const InstrumentGasKidUI = () => {
  const dispatch = useDispatch();
  const formInputRefs = useRef({});
  const inletData = useSelector(
      (state) => state.instrumentGasSkid.gasSkidData
  );

  const [selectedTime, setSelectedTime] = useState("");
  const [tempUpstream, setTempUpstream] = useState("");
  const [tempDownStream, setTempDownStream] = useState("");

  const instGasSkidPropertiesToAverage = useMemo(
      () => ["temp_upstream", "temp_downstream"],
      [] // Empty dependency array means it will only run once during component mount
  );

  const [average, setAverage] = useState(
      calculateAverageEntry(inletData, instGasSkidPropertiesToAverage)
  );


  /// update abg calculation
  useEffect(() => {
    setAverage(calculateAverageEntry(inletData, instGasSkidPropertiesToAverage));
  }, [inletData, instGasSkidPropertiesToAverage]);

  /// Update the averages
  useEffect(() => {
    dispatch(updateInstGasSkidAverage(average));
  },[dispatch, average]);

  const toast = useToast();


  const handleTime = (time) => {
    setSelectedTime(time);
  };

  const handleTempUp = (temp_up) => {
    setTempUpstream(temp_up);
  };

  const handleTempDown = (temp_down) => {
    setTempDownStream(temp_down);
  };

  function clearInputs() {
    formInputRefs.current.temp_upstream.clearInput();
    formInputRefs.current.temp_downstream.clearInput();
    setTempUpstream("");
    setTempDownStream("");
  }


  const dataEntry = {
    timeStamp: Date(),
    time: selectedTime,
    temp_upstream: tempUpstream,
    temp_downstream: tempDownStream,
  };

  const handleSaveEntry = (e) => {
    e.preventDefault();
    if (selectedTime === "" || tempUpstream === "" || tempDownStream === "") {
      toast({
        title: "NOTICE",
        description: "You have to fill all details to save",
        status: "error",
        position: "top-left",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      dispatch(addInstGasSkidData(dataEntry));
      clearInputs(); /// clear inputs
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Tab.Pane>
      <AppHeading
        headerTitle={"Instrument Gas Skid entry"}
        dividerLabel={"DATA ENTRY"}
      />

      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> <Label ribbon>TIME ⏰</Label></Table.HeaderCell>
            <Table.HeaderCell>TEMP UPSTREAM (C)</Table.HeaderCell>
            <Table.HeaderCell>TEMP DOWNSTREAM (C)</Table.HeaderCell>

            <Table.HeaderCell>ACTION</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <TimeSelector {...recordTimeFrames} onChange={handleTime} />
            </Table.Cell>
            <Table.Cell>
              {" "}
              <AppInput
                placeholder={"Temp Upstream (C)"}
                field={"temp_upstream"}
                formRef={formInputRefs}
                onChanged={handleTempUp}
                inputType={"number"}
              />
            </Table.Cell>
            <Table.Cell>
              {" "}
              <AppInput
                placeholder={"Temp Downstream (C) "}
                field={"temp_downstream"}
                formRef={formInputRefs}
                onChanged={handleTempDown}
                inputType={"number"}
              />
            </Table.Cell>

            <Table.Cell>
              <AppButton
                onclick={handleSaveEntry}
                variant="solid"
                label={"Save Entry"}
                color={"teal"}
              />{" "}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="white" px="4">
          DATA RESULTS
        </AbsoluteCenter>
      </Box>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> <Label ribbon>TIME ⏰</Label></Table.HeaderCell>
            <Table.HeaderCell>TEMP UPSTREAM (C)</Table.HeaderCell>
            <Table.HeaderCell>TEMP DOWNSTREAM (C)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {inletData.slice(1).map((data, index) => (
            <Table.Row key={index}>
              <Table.Cell>{data.time}</Table.Cell>
              <Table.Cell>{data.temp_upstream}</Table.Cell>
              <Table.Cell>{data.temp_downstream}</Table.Cell>
            </Table.Row>
          ))}

          <Table.Row>
            <Table.Cell>
              <Label ribbon>Average 👉</Label>
            </Table.Cell>
            <Table.Cell>{average.temp_upstream}</Table.Cell>
            <Table.Cell>{average.temp_downstream}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};
