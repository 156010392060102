import React, {useEffect, useMemo, useRef, useState} from "react";
import {Label, Tab, Table} from "semantic-ui-react";
import {
    Divider,
    AbsoluteCenter,
    Box, useToast
} from '@chakra-ui/react'
import {AppInput, AppHeading, TimeSelector, AppButton} from '../forms/AppForm'
import {recordTimeFrames} from "./UiComponents";
import {useDispatch, useSelector} from "react-redux";
import {addFilterSeparatorData, updateFilterSeparatorAverage} from "../../store/reducers/filterSeparatorReducer";
import {calculateAverageEntry} from "../../utils/helpers";


export function FilterSeparator() {

    const dispatch = useDispatch();
    const formInputRefs = useRef({});
    const inletData = useSelector(
        (state) => state.filterSeparator.filterSeparatorData);

    const [selectedTime, setSelectedTime] = useState("");
    const [hcLevel, setHCLevel] = useState("");
    const [gasTemperature, setGasTemp] = useState("");
    const [gasPressure, setGasPressure] = useState("");

    const filterSeparatorPropertiesToAverage = useMemo(
        () => ["gas_temp", "gas_pressure", "hc_level"],
        [] // Empty dependency array means it will only run once during component mount
    );


    const [average, setAverage] = useState(calculateAverageEntry(inletData, filterSeparatorPropertiesToAverage));


    useEffect(() => {
        setAverage(calculateAverageEntry(inletData, filterSeparatorPropertiesToAverage));
    }, [inletData, filterSeparatorPropertiesToAverage]);

    /// Update the averages
    useEffect(() => {
        dispatch(updateFilterSeparatorAverage(average));
    }, [dispatch, average]);

    const toast = useToast()

    const handleTime = (time) => {
        setSelectedTime(time)
    }

    const handleGasPressure = (pressure) => {
        setGasPressure(pressure)
    }

    const handleGasTemp = (gasTemp) => {
        setGasTemp(gasTemp);
    }

    const handleHCLevel = (level) => {
        setHCLevel(level);
    }


    function clearInputs() {
        formInputRefs.current.temperature.clearInput();
        formInputRefs.current.pressure.clearInput();
        formInputRefs.current.hc_level.clearInput();
        setGasTemp('');
        setGasPressure('')
        setHCLevel('')
        setSelectedTime('')
    }

    const getGroupAverage = (averageData) => {
        const {gas_pressure, gas_temp, hc_level} = averageData;
        return parseFloat(gas_pressure) + parseFloat(gas_temp) + parseFloat(hc_level) / Object.keys(averageData).length;
    }

    const dataEntry = {
        "timeStamp": Date(),
        "time": selectedTime,
        "gas_temp": gasTemperature,
        "gas_pressure": gasPressure,
        "hc_level": hcLevel
    }

    const handleSaveEntry = (e) => {
        e.preventDefault();
        if (selectedTime === '' || gasTemperature === '' || gasPressure === '' || hcLevel === '') {
            toast({
                title: 'NOTICE',
                description: "You have to fill all details to save",
                status: 'error',
                position: 'top-left',
                duration: 9000,
                isClosable: true,
            })
            return;
        }

        try {
            dispatch(addFilterSeparatorData(dataEntry))
            clearInputs()      /// clear inputs


        } catch (e) {
            console.log(e);
        }

    }
    return (<Tab.Pane>
        <AppHeading
            headerTitle={"Filter Separator entry"}
            dividerLabel={"DATA ENTRY"}
        />

        <Table singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        {" "}
                        <Label ribbon>TIME ⏰</Label>
                    </Table.HeaderCell>
                    <Table.HeaderCell>GAS TEMPERATURE (C)</Table.HeaderCell>
                    <Table.HeaderCell>GAS PRESSURE (Barg)</Table.HeaderCell>
                    <Table.HeaderCell>HC LEVEL (%)</Table.HeaderCell>
                    {/*<Table.HeaderCell>ACTION ⚙️</Table.HeaderCell>*/}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <TimeSelector {...recordTimeFrames} onChange={handleTime}/>
                    </Table.Cell>
                    <Table.Cell>
                        {" "}
                        <AppInput
                            onClear={clearInputs}
                            field={"temperature"}
                            formRef={formInputRefs}
                            placeholder={"Gas Temprature (C)"}
                            onChanged={handleGasTemp}
                            inputType={"number"}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        {" "}
                        <AppInput
                            onClear={clearInputs}
                            field={"pressure"}
                            formRef={formInputRefs}
                            placeholder={"Gas Pressure (Barg) "}
                            onChanged={handleGasPressure}
                            inputType={"number"}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <AppInput
                            onClear={clearInputs}
                            field={"hc_level"}
                            formRef={formInputRefs}
                            placeholder={"HC Level (%)"}
                            onChanged={handleHCLevel}
                            inputType={"number"}
                        />
                    </Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                        {" "}
                        <AppButton
                            onclick={handleSaveEntry}
                            variant="solid"
                            label={"Save Entry"}
                            color={"teal"}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

        <Box position="relative" padding="10">
            <Divider/>
            <AbsoluteCenter bg="white" px="4">
                DATA RESULTS
            </AbsoluteCenter>
        </Box>
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        {" "}
                        <Label ribbon>TIME ⏰</Label>
                    </Table.HeaderCell>
                    <Table.HeaderCell>GAS TEMPERATURE (C)</Table.HeaderCell>
                    <Table.HeaderCell>GAS PRESSURE (Barg)</Table.HeaderCell>
                    <Table.HeaderCell>HC LEVEL (%)</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {inletData.slice(1).map((data, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>{data.time}</Table.Cell>
                        <Table.Cell>{data.gas_temp}</Table.Cell>
                        <Table.Cell>{data.gas_pressure}</Table.Cell>
                        <Table.Cell>{data.hc_level}</Table.Cell>
                    </Table.Row>
                ))}

                <Table.Row>
                    <Table.Cell>
                        <Label ribbon>Average 🧮</Label>
                    </Table.Cell>
                    <Table.Cell>{average.gas_temp}</Table.Cell>
                    <Table.Cell>{average.gas_pressure}</Table.Cell>
                    <Table.Cell>{average.hc_level}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Label ribbon>Group Average 🧮</Label>
                    </Table.Cell>
                    <Table.Cell colSpan='3'>{getGroupAverage(average)}</Table.Cell>

                </Table.Row>
            </Table.Body>
        </Table>
    </Tab.Pane>);
}

