import React from "react";

import {
    Box,
} from "@chakra-ui/react";

import {AppHeading} from "../forms/AppForm";
import {Tab} from "semantic-ui-react";
import {LineA, LineB} from "./UiComponents";

export function RegulationSkid() {

    const panes = [

        {menuItem: 'LINE-A', render: () => <Tab.Pane><LineA/></Tab.Pane>},
        {menuItem: 'LINE-B', render: () => <Tab.Pane><LineB/></Tab.Pane>},
    ]
    return (<Tab.Pane>
        <AppHeading headerTitle={'REGULATION SKID'} dividerLabel={'👇👇👇👇'}/>
        <Box>
            <Tab panes={panes}/>
        </Box>
    </Tab.Pane>);

}
