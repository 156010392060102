import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    regulationSkidLineBEntries: [
        {
            timeStamp: '',
            time: '',
            outlet_temp: '',
            outlet_pressure: '',

        },
    ],
    regulationSkidLineBAverage: {},
}
const regulationSkidLineBSlice = createSlice({
    name: 'regulationSkidLineBEntries',
    initialState: initialState,
    reducers: {
        addRegulationSkidLineBData: (state, {payload}) => {
            const {outlet_temp, time, timeStamp, outlet_pressure} = payload
            state.regulationSkidLineBEntries.push({
                timeStamp: timeStamp,
                time: time,
                outlet_temp: outlet_temp,
                outlet_pressure: outlet_pressure,
            })
        },
        updateRegulationSkidLineBAverage(state, {payload}) {
            const {outlet_temp, outlet_pressure} = payload
            state.regulationSkidLineBAverage = {
                outlet_temp: outlet_temp,
                outlet_pressure: outlet_pressure,

            }
        },
    },
})

export const {addRegulationSkidLineBData, updateRegulationSkidLineBAverage} = regulationSkidLineBSlice.actions

export default regulationSkidLineBSlice.reducer
