import React, {useEffect, useMemo, useRef, useState} from "react";
import {Tab} from 'semantic-ui-react';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Divider,
    TableCaption,
    AbsoluteCenter,
    Box, VStack,
    TableContainer, useToast
} from '@chakra-ui/react'
import {AppInput, AppHeading, TimeSelector, AppButton} from '../../forms/AppForm'
import {useDispatch, useSelector} from "react-redux";
import {calculateAverageEntry} from "../../../utils/helpers";
import {recordTimeFrames} from "../UiComponents";
import {addHeaterHTAData, updateHeaterHTAAverage} from "../../../store/reducers/gasHeaterHTAReducer";


export function HTAEntry() {

    const dispatch = useDispatch();
    const formInputRefs = useRef({});
    const inletData = useSelector(
        (state) => state.gasHeaterHTA.gasHeaterHTAEntries);

    const [selectedTime, setSelectedTime] = useState("");
    const [waterLevel, setWaterLevel] = useState("");
    const [waterTemp, setWaterTemp] = useState("");
    const [fuelGasTemp, setFuelGasTemp] = useState("");
    const [fuelGasPressure, setFuelGasPressure] = useState("");


    const gasHeaterHTAPropertiesToAverage = useMemo(
        () => ["water_level", "water_temp", "fuel_gas_pressure", "fuel_gas_temp"],
        []);



    const getGroupAverage = (averageData) => {
        const {water_level, water_temp, fuel_gas_pressure, fuel_gas_temp} = averageData;
        return parseFloat(water_level) + parseFloat(water_temp) + parseFloat(fuel_gas_pressure) + parseFloat(fuel_gas_temp) / Object.keys(averageData).length;
    }



    const [average, setAverage] = useState(
        calculateAverageEntry(inletData, gasHeaterHTAPropertiesToAverage)
    );

    useEffect(() => {
        setAverage(calculateAverageEntry(inletData, gasHeaterHTAPropertiesToAverage));
    }, [inletData, gasHeaterHTAPropertiesToAverage]);

    /// Update the averages
    useEffect(() => {
        dispatch(updateHeaterHTAAverage(average));
    }, [dispatch, average]);
    const toast = useToast()




    const handleTime = (time) => {
        setSelectedTime(time)
    }

    const handleWaterLevel = (level) => {
        setWaterLevel(level)
    }

    const handleWaterTemp = (waterTemp) => {
        setWaterTemp(waterTemp);
    }

    const handleFuelGasPressure = (prs) => {
        setFuelGasPressure(prs);
    }

    const handleFuelGasTemp = (temp) => {
        setFuelGasTemp(temp);
    }

    function clearInputs() {
        formInputRefs.current.water_level.clearInput();
        formInputRefs.current.water_temp.clearInput();
        formInputRefs.current.fuel_gas_pressure.clearInput();
        formInputRefs.current.fuel_gas_temp.clearInput();
        setWaterLevel('')
        setWaterTemp('')
        setFuelGasPressure('')
        setFuelGasTemp('')
    }
    const dataEntry = {
        "timeStamp": Date(),
        "time": selectedTime,
        "water_level": waterLevel,
        "water_temp": waterTemp,
        "fuel_gas_pressure": fuelGasPressure,
        "fuel_gas_temp": fuelGasTemp,
    }

    const handleSaveEntry = (e) => {
        e.preventDefault();
        if (selectedTime === '' || waterTemp === '' || waterLevel === '' || fuelGasPressure === '' || fuelGasTemp === '') {
            toast({
                title: 'NOTICE',
                description: "You have to fill all details to save",
                status: 'error',
                position: 'top-left',
                duration: 9000,
                isClosable: true,
            })
            return;
        }

        try {


            dispatch(addHeaterHTAData(dataEntry));     /// clear inputs
            clearInputs();
        } catch (e) {
            console.log(e);
        }
    }
    return (<Tab.Pane>
        <AppHeading headerTitle={'HT-A entry'} dividerLabel={'DATA ENTRY'}/>
        <VStack
            spacing={4}
            align='stretch'
        >
            <TableContainer>
                <Table variant='simple'>
                    <TableCaption>Station inlet periodic entry</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>TIME</Th>
                            <Th>WATER LEVEL (%)</Th>
                            <Th>WATER TEMP (C)</Th>
                            <Th>FUEL GAS PRESSURE (Barg)</Th>
                            <Th>FUEL GAS TEMP (C)</Th>
                            <Th isNumeric>ACTION</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <TimeSelector {...recordTimeFrames} onChange={handleTime}/>
                            </Td>
                            <Td><AppInput
                                placeholder={'water level (%)'}
                                formRef={formInputRefs}
                                field={'water_level'}
                                onChanged={handleWaterLevel}
                                inputType={'number'}/></Td>
                            <Td><AppInput
                                placeholder={'water temp (C) '}
                                formRef={formInputRefs}
                                field={'water_temp'}
                                onChanged={handleWaterTemp}
                                inputType={'number'}/></Td>
                            <Td><AppInput
                                placeholder={'fuel gas pressure (Barg)'}
                                formRef={formInputRefs}
                                field={'fuel_gas_pressure'}
                                onChanged={handleFuelGasPressure}
                                inputType={'number'}/></Td>
                            <Td><AppInput
                                placeholder={'fuel gas temp (C)'}
                                formRef={formInputRefs}
                                field={'fuel_gas_temp'}
                                onChanged={handleFuelGasTemp}
                                inputType={'number'}/></Td>
                            <Td><AppButton onclick={handleSaveEntry} variant='solid'
                                           label={'Save Entry'} color={'teal'}/> </Td>
                        </Tr>

                    </Tbody>

                </Table>
            </TableContainer>
            <Box position='relative' padding='10'>
                <Divider/>
                <AbsoluteCenter bg='white' px='4'>
                    DATA RESULTS
                </AbsoluteCenter>
            </Box>
            <TableContainer>
                <Table variant='striped' colorScheme='gray'>
                    <TableCaption>Station inlet periodic logs</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>TIME</Th>
                            <Th>WATER LEVEL (%)</Th>
                            <Th>WATER TEMP (C)</Th>
                            <Th>FUEL GAS PRESSURE (Barg)</Th>
                            <Th>FUEL GAS TEMP (C)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {inletData.slice(1).map((data, index) => (
                            <Tr key={index}>
                                <Td>{data.time}</Td>
                                <Td>{data.water_level}</Td>
                                <Td>{data.water_temp}</Td>
                                <Td>{data.fuel_gas_pressure}</Td>
                                <Td>{data.fuel_gas_temp}</Td>
                            </Tr>
                        ))}
                        <Tr>
                            <Td>Average 👉</Td>
                            <Td>{average.water_level}</Td>
                            <Td>{average.water_temp}</Td>
                            <Td>{average.fuel_gas_pressure}</Td>
                            <Td>{average.fuel_gas_temp}</Td>
                            <Td></Td>
                        </Tr>
                        <Tr>
                            <Td>Group Average 👉</Td>
                            <Td>{getGroupAverage(average)}</Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

        </VStack>


    </Tab.Pane>);
}