import { Heading } from "@chakra-ui/react";

import { Grid, Tab, Accordion } from "semantic-ui-react";

import {
  averagePanes,
  mainAppTabsPanes,
  rootPanels,
} from "./AccordioAndTabsPanels";
import { style } from "./styles";

export default function AppCore() {
  return (
    <div>
      <Grid columns={2} stackable  style={style.body}>
        <Grid.Column width={12}>
          <Heading
            mt={{ base: "10", md: "3" }}
            mb="4"
            textAlign={"left"}
            maxW={"4xl"}
            fontSize={{ base: "24px", md: "40px", lg: "42px" }}
          >
            GAS Distribution xxxx.
          </Heading>
          <Heading
            mt={{ base: "0", md: "1" }}
            mb="8"
            textAlign={"left"}
            maxW={"4xl"}
            fontSize={{ base: "14px", md: "20px", lg: "21px" }}
          >
            Equipment Performance chart for xxxx Metering station
          </Heading>
        </Grid.Column>

        <Grid.Column width={12}>
          <Grid.Row>
            <Grid.Column>
              <Tab
                menu={{ fluid: true, vertical: true, tabular: true }}
                panes={mainAppTabsPanes}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={4}>
          <Accordion defaultActiveIndex={0} panels={rootPanels} styled />
        </Grid.Column>
        <Grid.Column width={12}>
          <Heading
            mt={{ base: "8", md: "3" }}
            mb="4"
            textAlign={"left"}
            maxW={"4xl"}
            fontSize={{ base: "24px", md: "40px", lg: "42px" }}
          >
            Averages & remarks
          </Heading>
          <Heading
            mt={{ base: "0", md: "1" }}
            mb="8"
            textAlign={"left"}
            maxW={"4xl"}
            fontSize={{ base: "14px", md: "20px", lg: "21px" }}
          >
            STATION INLET & STATION OUTLET AVERAGES: PRESSURE & TEMP
          </Heading>

          <Tab panes={averagePanes} />
        </Grid.Column>
      </Grid>
    </div>
  );
}
