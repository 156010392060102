import React from "react";
import {Select,} from '@chakra-ui/react'

export const TimeSelector = ({times, onChange, label}) => {
    const handleTime = (e) => {
        onChange(e.target.value)
    }
    return (
        <Select  placeholder={label} onChange={handleTime}>
            {times.map((item, index) => (
                <option key={index}>{item}</option>
            ))}
        </Select>
    );
}