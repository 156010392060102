import React, {useEffect, useState, useRef, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Label, Tab, Table} from "semantic-ui-react";
import {Divider, AbsoluteCenter, Box, useToast} from "@chakra-ui/react";

import {
    AppInput,
    AppHeading,
    TimeSelector,
    AppButton,
} from "../forms/AppForm";

import {
    addInletData,
    updateInletAverage,
} from "../../store/reducers/stationInletReducer";
import {recordTimeFrames} from "./UiComponents";
import {calculateAverageEntry} from "../../utils/helpers";

export function StationInletUI() {
    const dispatch = useDispatch();
    const formInputRefs = useRef({});
    const inletdata = useSelector(
        (state) => state.stationInletEntry.inletEntries
    );

    const [selectedTime, setSelectedTime] = useState("");
    const [pressure, setPressure] = useState("");
    const [prs, setPRS] = useState("");
    const [temperature, setTemperature] = useState("");


    const inletPropertiesToAverage = useMemo(
        () => ["pressure", "prs", "temperature"],
        []);


    /// calculate the avg
    const [average, setAverage] = useState(
        calculateAverageEntry(inletdata, inletPropertiesToAverage)
    );

    /// update abg calculation
    useEffect(() => {
        setAverage(calculateAverageEntry(inletdata, inletPropertiesToAverage));
    }, [inletdata, inletPropertiesToAverage]);

    /// Update the averages
    useEffect(() => {
        dispatch(updateInletAverage(average));
    }, [dispatch, average]);

    const toast = useToast();

    const handleTime = (time) => {
        setSelectedTime(time);
    };

    const handlePressure = (pressure) => {
        setPressure(pressure);
    };

    const handlePRS = (prs) => {
        setPRS(prs);
    };

    const handleTemperature = (temp) => {
        setTemperature(temp);
    };

    function clearInputs() {
        formInputRefs.current.pressure.clearInput();
        formInputRefs.current.prs.clearInput();
        formInputRefs.current.temp.clearInput();
        setTemperature("");
        setPressure("");
        setPRS("");
        setSelectedTime("");
    }

    const handleSaveEntry = (e) => {
        e.preventDefault();
        if (
            selectedTime === "" ||
            pressure === "" ||
            prs === "" ||
            temperature === ""
        ) {
            toast({
                title: "NOTICE",
                description: "You have to fill all details to save",
                status: "error",
                position: "top-left",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            const data = {
                timeStamp: Date(),
                time: selectedTime,
                pressure: pressure,
                prs: prs,
                temperature: temperature,
            };
            dispatch(addInletData(data));
            clearInputs(); /// clear inputs
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <Tab.Pane>
            <AppHeading
                headerTitle={"Station inlet entry"}
                dividerLabel={"DATA ENTRY"}
            />

            <Table singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            {" "}
                            <Label ribbon>TIME ⏰</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell>PRESSURE (Barg)e</Table.HeaderCell>
                        <Table.HeaderCell>FIRST STAGE PRS(Barg)</Table.HeaderCell>
                        <Table.HeaderCell>TEMP (C)</Table.HeaderCell>
                        {/* <Table.HeaderCell>ACTION ⚙️</Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <TimeSelector  {...recordTimeFrames} onChange={handleTime}/>
                        </Table.Cell>
                        <Table.Cell>
                            {" "}
                            <AppInput
                                onClear={clearInputs}
                                field={"pressure"}
                                formRef={formInputRefs}
                                placeholder={"pressure (Barg)"}
                                onChanged={handlePressure}
                                inputType={"number"}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            {" "}
                            <AppInput
                                onClear={clearInputs}
                                field={"prs"}
                                formRef={formInputRefs}
                                placeholder={"first stage prs (Barg) "}
                                onChanged={handlePRS}
                                inputType={"number"}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <AppInput
                                onClear={clearInputs}
                                field={"temp"}
                                formRef={formInputRefs}
                                placeholder={"temprature (c)"}
                                onChanged={handleTemperature}
                                inputType={"number"}
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                            {" "}
                            <AppButton
                                onclick={handleSaveEntry}
                                variant="solid"
                                label={"Save Entry"}
                                color={"teal"}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Box position="relative" padding="10">
                <Divider/>
                <AbsoluteCenter bg="white" px="4">
                    DATA RESULTS
                </AbsoluteCenter>
            </Box>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            {" "}
                            <Label ribbon>TIME ⏰</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell>PRESSURE (Barg)</Table.HeaderCell>
                        <Table.HeaderCell>FIRST STAGE PRS(Barg)</Table.HeaderCell>
                        <Table.HeaderCell>TEMP (C)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {inletdata.slice(1).map((data, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{data.time}</Table.Cell>
                            <Table.Cell>{data.pressure}</Table.Cell>
                            <Table.Cell>{data.prs}</Table.Cell>
                            <Table.Cell>{data.temperature}</Table.Cell>
                        </Table.Row>
                    ))}

                    <Table.Row>
                        <Table.Cell>
                            <Label ribbon>Average 🧮</Label>
                        </Table.Cell>
                        <Table.Cell>{average.pressure}</Table.Cell>
                        <Table.Cell>{average.prs}</Table.Cell>
                        <Table.Cell>{average.temperature}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Tab.Pane>
    );
}
