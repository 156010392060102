// eslint-disable-next-line import/no-anonymous-default-export
export default {
  entryData: {
    inletEntries: [
      {
        timeStamp: '',
        time: '',
        pressure: '',
        prs: '',
        temperature: '',
      },
    ],
    average: {},
  },
}
