import React from "react";
import {Tab} from 'semantic-ui-react'
import {Box,} from '@chakra-ui/react'

import {AppHeading, AppTab} from '../forms/AppForm'
import {
    HTAEntry,
    HTBEntry,
} from './UiComponents'

export function GasHeater() {
    const panes = [

        {menuItem: 'HT-A', render: () => <Tab.Pane><HTAEntry/></Tab.Pane>},
        {menuItem: 'HT-B', render: () => <Tab.Pane><HTBEntry/></Tab.Pane>},
    ]
    return (<Tab.Pane>
        <AppHeading headerTitle={'GAS HEATER'} dividerLabel={'👇👇👇👇'}/>
        <Box>
            <Tab panes={panes}/>
        </Box>
    </Tab.Pane>);
}