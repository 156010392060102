import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    regulationSkidLineAEntries: [
        {
            timeStamp: '',
            time: '',
            outlet_temp: '',
            outlet_pressure: '',

        },
    ],
    regulationSkidLineAAverage: {},
}
const regulationSkidLineASlice = createSlice({
    name: 'regulationSkidLineAEntries',
    initialState: initialState,
    reducers: {
        addRegulationSkidLineAData: (state, {payload}) => {
            const {outlet_temp, time, timeStamp, outlet_pressure} = payload
            state.regulationSkidLineAEntries.push({
                timeStamp: timeStamp,
                time: time,
                outlet_temp: outlet_temp,
                outlet_pressure: outlet_pressure,
            })
        },
        updateRegulationSkidLineAAverage(state, {payload}) {
            const {outlet_temp, outlet_pressure} = payload
            state.regulationSkidLineAAverage = {
                outlet_temp: outlet_temp,
                outlet_pressure: outlet_pressure,

            }
        },
    },
})

export const {addRegulationSkidLineAData, updateRegulationSkidLineAAverage} = regulationSkidLineASlice.actions

export default regulationSkidLineASlice.reducer
