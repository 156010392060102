
import { StationInletUI } from "./StationInletTab";
import { InletAverage } from "./averages-remarks-section/InletAverage";
import { OutletAverages } from "./averages-remarks-section/OutletAverages";
import { InstrumentGasKidUI } from "./InstrumentGasSkidTab";
import {
  HeaterContent,
  MeteringSkidContent,
  PowerOutageContent,
  PressureReduSkidContent,
  SeparatorContent,
} from "./EquimentTabsContent";
import { StationOutlet } from "./StationOutletTab";
import {FilterSeparator, GasHeater, RegulationSkid} from "./UiComponents";

/// Equipments status Accordion panels
export const rootPanels = [
  {
    key: "panel-heater",
    title: "HEATERS",
    content: { content: <HeaterContent /> },
  },
  {
    key: "panel-metering-skid",
    title: "METERING SKID",
    content: { content: <MeteringSkidContent /> },
  },
  {
    key: "panel-pressure-reduc-skid",
    title: "PRESSURE REDUCTION SKID",
    content: { content: <PressureReduSkidContent /> },
  },
  {
    key: "panel-seperator",
    title: "SEPARATOR",
    content: { content: <SeparatorContent /> },
  },
  {
    key: "panel-power-out",
    title: "POWER OUTAGES",
    content: { content: <PowerOutageContent /> },
  },
];

/// End of Equipment Acordion

// main tabs panes start
export const mainAppTabsPanes = [
  {
    menuItem: "STATION INLET",
    render: () => <StationInletUI />,
  },
  {
    menuItem: "INSTRUMENT GAS SKID",
    render: () => <InstrumentGasKidUI />,
  },
  {
    menuItem: "FILTER SEPARATOR",
    render: () => <FilterSeparator/>,
  },
  { menuItem: "GAS HEATER", render: () => <GasHeater/> },
  {
    menuItem: "REGULATION SKID",
    render: () => <RegulationSkid/>,
  },
  {
    menuItem: "STATION OUTLET",
    render: () => <StationOutlet />,
  },
];

export const averagePanes = [
  { menuItem: "INLET", render: () => <InletAverage /> },
  { menuItem: "OUTLET", render: () => <OutletAverages /> },
];
