import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    filterSeparatorData: [
        {
            timeStamp: '',
            time: '',
            gas_temp: '',
            gas_pressure: '',
            hc_level: '',
        },
    ],
    filterSepAverage: {},
}
const filterSeparatorSlice = createSlice({
    name: 'filterSeparatorData',
    initialState: initialState,
    reducers: {
        addFilterSeparatorData: (state, {payload}) => {
            const {gas_temp, gas_pressure, hc_level, time, timeStamp} = payload
            state.filterSeparatorData.push({
                timeStamp: timeStamp,
                time: time,
                gas_temp: gas_temp,
                gas_pressure: gas_pressure,
                hc_level: hc_level,

            })
        },
        updateFilterSeparatorAverage(state, {payload}) {
            const {gas_pressure,gas_temp, hc_level} = payload
            state.filterSepAverage = {
                gas_temp: gas_temp,
                gas_pressure: gas_pressure,
                hc_level: hc_level,
            }
        },
    },
})

export const {addFilterSeparatorData, updateFilterSeparatorAverage} = filterSeparatorSlice.actions

export default filterSeparatorSlice.reducer
