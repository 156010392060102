import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  outletEntries: [
    {
      timeStamp: '',
      time: '',
      pressure: '',
      temperature: '',
    },
  ],
  outletAverage: {},
}
const stationOutletSlice = createSlice({
  name: 'outletEntries',
  initialState: initialState,
  reducers: {
    addOutletData: (state, { payload }) => {
      const { pressure, time, timeStamp, temperature } = payload
      state.outletEntries.push({
        timeStamp: timeStamp,
        time: time,
        pressure: pressure,
        temperature: temperature,
      })
    },
    updateOutletAverage(state, { payload }) {
      const { pressure, temperature } = payload
      state.outletAverage = {
        pressure: pressure,
        temperature: temperature,
      }
    },
  },
})

export const { addOutletData, updateOutletAverage } = stationOutletSlice.actions

export default stationOutletSlice.reducer
