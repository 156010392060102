import { Form } from "semantic-ui-react";
import React, { useState, useEffect, useCallback } from "react";

import FormInput from "../../../forms/FormInput";

const OutageEnd = () => {
  const [end, setEnd] = useState("");

  const handleNameChange = (newName) => {
    setEnd(newName);
  };

  const handleSubmit = useCallback(() => {
    handleNameClear();
  }, []);

  useEffect(() => {
    if (end === "") {
      handleSubmit();
    }
  }, [end, handleSubmit]);
  const handleNameClear = () => {
    setEnd("");
    // Additional clear logic if needed
    console.log("Name cleared");
  };

  return (
    <div style={{ margin: "20px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <FormInput
            value={end}
            type={"time"}
            onChange={handleNameChange}
            onClear={handleNameClear}
            placeholder="Enter End"
          />

          <Form.Button content="Submit" />
        </Form.Group>
      </Form>
    </div>
  );
};
export default OutageEnd;
