import { InputGroup, InputLeftAddon, Stack, Input } from "@chakra-ui/react";
import { Tab } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

export function InletAverage() {
  const inletdata = useSelector((state) => state.stationInletEntry.average);
  const inletdataList = useSelector(
    (state) => state.stationInletEntry.inletEntries
  );

  const [inletPressAt6, setInletPressAt6] = useState("");

  /// Update the averages
  useEffect(() => {
    setInletPressAt6(getPrsAtSix(inletdataList));
  }, [inletdataList]);

  function getPrsAtSix(entries) {
    for (let entry of entries) {
      if (entry.time === "06:00") {
        return entry.pressure;
      }
    }
    return ""; // Return null if no entry with time '06:00' is found
  }
  return (
    <Tab.Pane>
      <Stack spacing={4} pt={16}>
        <InputGroup>
          <InputLeftAddon children="AVG INLET PRES(Barg)" />
          <Input
            value={inletdata.prs || ""}
            htmlSize={25}
            width="auto"
            type="text"
            isDisabled={true}
            _disabled={{ color: "black", border: "2px", borderColor: "teal" }}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="INLET PRES @6:00 (Barg)" />
          <Input
            value={inletPressAt6 || ""}
            htmlSize={25}
            width="auto"
            type="text"
            isDisabled={true}
            _disabled={{ color: "black", border: "2px", borderColor: "teal" }}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="AVG INLET TEMP (C)" />
          <Input
            value={inletdata.temperature || ""}
            htmlSize={25}
            width="auto"
            type="text"
            placeholder="phone number"
            isDisabled={true}
            _disabled={{ color: "black", border: "2px", borderColor: "teal" }}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="CALORIFIC VALUE(BTU/SCF)" />
          <Input
            htmlSize={25}
            width="auto"
            type="tel"
            placeholder="Input value"
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="CONDENSATE VOL DRAINED (%)" />
          <Input
            htmlSize={25}
            width="auto"
            type="tel"
            placeholder="Input value"
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="TURBINE METER VALUE @6:00" />
          <Input
            htmlSize={25}
            width="auto"
            type="tel"
            placeholder="Input value"
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="VOLUME MEASURED" />
          <Input
            htmlSize={25}
            width="auto"
            type="text"
            isDisabled={true}
            _disabled={{ color: "black", border: "2px", borderColor: "teal" }}
          />
        </InputGroup>
      </Stack>
    </Tab.Pane>
  );
}
