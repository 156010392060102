import { createSlice } from '@reduxjs/toolkit'
import initialState from '../initialState'

const stationInletSlice = createSlice({
  name: 'inletEntries',
  initialState: initialState.entryData,
  reducers: {
    addInletData: (state, { payload }) => {
      const { prs, pressure, time, timeStamp, temperature } = payload
      state.inletEntries.push({
        timeStamp: timeStamp,
        time: time,
        pressure: pressure,
        prs: prs,
        temperature: temperature,
      })
    },
    updateInletAverage(state, { payload }) {
      const { prs, pressure, temperature } = payload
      state.average = {
        prs: prs,
        pressure: pressure,
        temperature: temperature,
      }
    },
  },
})

export const { addInletData, updateInletAverage } = stationInletSlice.actions

export default stationInletSlice.reducer
