import React, {useEffect, useMemo, useRef, useState} from "react";
import {Tab} from 'semantic-ui-react';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Divider,
    TableCaption,
    AbsoluteCenter,
    Box, VStack,
    TableContainer, useToast
} from '@chakra-ui/react'
import {AppInput, AppHeading, TimeSelector, AppButton} from '../../forms/AppForm'
import {useDispatch, useSelector} from "react-redux";
import {calculateAverageEntry} from "../../../utils/helpers";
import {
    addRegulationSkidLineAData,
    updateRegulationSkidLineAAverage
} from "../../../store/reducers/regulationSkidLineAReducer";
import {recordTimeFrames} from "../UiComponents";


export function LineA() {

    const dispatch = useDispatch();
    const formInputRefs = useRef({});
    const inletData = useSelector(
        (state) => state.regSkidLineA.regulationSkidLineAEntries
    );


    const [selectedTime, setSelectedTime] = useState("");
    const [outletTemp, setTemp] = useState("");
    const [outletPressure, setPressure] = useState("");

    const regSkidLineAPropertiesToAverage = useMemo(
        () => ["outlet_temp", "outlet_pressure",],
        []);


    const getGroupAverage = (averageData) => {
        const {outlet_temp, outlet_pressure} = averageData;
        return (parseFloat(outlet_temp) + parseFloat(outlet_pressure)) / Object.keys(averageData).length;
    }



    const [average, setAverage] = useState(
        calculateAverageEntry(inletData, regSkidLineAPropertiesToAverage)
    );

    useEffect(() => {
        setAverage(calculateAverageEntry(inletData, regSkidLineAPropertiesToAverage));
    }, [inletData, regSkidLineAPropertiesToAverage]);

    /// Update the averages
    useEffect(() => {
        dispatch(updateRegulationSkidLineAAverage(average));
    }, [dispatch, average]);


    const toast = useToast()




    const handleTime = (time) => {
        setSelectedTime(time)
    }

    const handlePressure = (pressure) => {
        setPressure(pressure)
    }

    const handleTemp = (temp) => {
        setTemp(temp);
    }


    function clearInputs() {
        formInputRefs.current.outlet_temp.clearInput();
        formInputRefs.current.outlet_pressure.clearInput();
        setTemp('');
        setPressure('')
        setSelectedTime('')
    }

    const dataEntry = {
        "timeStamp": Date(),
        "time": selectedTime,
        "outlet_temp": outletTemp,
        "outlet_pressure": outletPressure,
    }
    const handleSaveEntry = (e) => {
        e.preventDefault();
        if (selectedTime === '' || outletPressure === '' || outletTemp === '') {
            toast({
                title: 'NOTICE',
                description: "You have to fill all details to save",
                status: 'error',
                position: 'top-left',
                duration: 9000,
                isClosable: true,
            })
            return;
        }
        try {
            dispatch(addRegulationSkidLineAData(dataEntry))
            clearInputs()      /// clear inputs
        } catch (e) {
            console.log(e);
        }

    }
    return (<Tab.Pane>
        <AppHeading headerTitle={'LINE-A entry'} dividerLabel={'DATA ENTRY'}/>
        <VStack
            spacing={4}
            align='stretch'
        >
            <TableContainer>
                <Table variant='simple'>
                    <TableCaption>Station inlet periodic entry</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>TIME</Th>
                            <Th>OUTLET TEMP (C)</Th>
                            <Th>OUTLET PRESSURE (Barg)</Th>

                            <Th isNumeric>ACTION</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <TimeSelector {...recordTimeFrames} onChange={handleTime}/>
                            </Td>

                            <Td><AppInput
                                placeholder={'outlet temp (C) '}
                                formRef={formInputRefs}
                                field={'outlet_temp'}
                                onChanged={handleTemp}
                                inputType={'number'}/></Td>
                            <Td><AppInput
                                placeholder={'outlet pressure (Barg)'}
                                onChanged={handlePressure}
                                formRef={formInputRefs}
                                field={'outlet_pressure'}
                                inputType={'number'}/></Td>

                            <Td><AppButton onclick={handleSaveEntry} variant='solid'
                                           label={'Save Entry'} color={'teal'}/> </Td>
                        </Tr>

                    </Tbody>

                </Table>
            </TableContainer>
            <Box position='relative' padding='10'>
                <Divider/>
                <AbsoluteCenter bg='white' px='4'>
                    DATA RESULTS
                </AbsoluteCenter>
            </Box>
            <TableContainer>
                <Table variant='striped'>
                    <TableCaption>Station inlet periodic logs</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>TIME</Th>
                            <Th>OUTLET TEMP (C)</Th>
                            <Th>OUTLET PRESSURE (Barg)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {inletData.slice(1).map((data, index) => (
                            <Tr key={index}>
                                <Td>{data.time}</Td>
                                <Td>{data.outlet_temp}</Td>
                                <Td>{data.outlet_pressure}</Td>
                            </Tr>

                        ))}
                        <Tr>
                            <Td>Average 👉</Td>
                            <Td>{average.outlet_temp}</Td>
                            <Td>{average.outlet_pressure}</Td>
                            <Td></Td>
                        </Tr>
                        <Tr>
                            <Td>Group Average 👉</Td>
                            <Td>{getGroupAverage(average)}</Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>
                    </Tbody>


                </Table>
            </TableContainer>

        </VStack>


    </Tab.Pane>);
}