import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { StrictMode } from 'react'
import NNPCDataGrabApp from './pages/Index'
import { Provider } from 'react-redux'
import store from './store'

function App() {
  return (
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ChakraProvider>
            <NNPCDataGrabApp />
          </ChakraProvider>
        </Provider>
      </BrowserRouter>
    </StrictMode>
  )
}

export default App
