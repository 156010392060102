import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

const FormInput = ({ value, onChange, onClear, placeholder, type }) => {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleChange = (_, { value }) => {
    setInputValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (inputValue === "") {
      onClear();
    }
  }, [inputValue, onClear]);

  return (
    <Form.Input
      type={type}
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
    />
  );
};

export default FormInput;
