import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    gasSkidData: [
        {
            timeStamp: '',
            time: '',
            temp_upstream: '',
            temp_downstream: '',
        },
    ],
    gasSkidAverage: {},
}
const instrumentGasSkidSlice = createSlice({
    name: 'gasSkidData',
    initialState: initialState,
    reducers: {
        addInstGasSkidData: (state, {payload}) => {
            const {temp_downstream, temp_upstream, time, timeStamp} = payload
            state.gasSkidData.push({
                timeStamp: timeStamp,
                time: time,
                temp_upstream: temp_upstream,
                temp_downstream: temp_downstream,

            })
        },
        updateInstGasSkidAverage(state, {payload}) {
            const {temp_upstream, temp_downstream} = payload
            state.gasSkidAverage = {
                temp_downstream: temp_downstream,
                temp_upstream: temp_upstream,
            }
        },
    },
})

export const {addInstGasSkidData, updateInstGasSkidAverage} = instrumentGasSkidSlice.actions

export default instrumentGasSkidSlice.reducer
