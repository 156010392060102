"use client";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Flex,
    Text,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Image,
    Box,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import logos from "../components/ui/Images";
import {handleNavigateClick} from "../utils/helpers";

export default function AdminLogin() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        location: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSignIn = async () => {
        setIsLoading(true);
        console.log(formData);

        try {
            const response = await axios.post(
                "https://xyx.com/api/v1/signup",
                formData
            );

            // Handle the response here (e.g., redirect to the next page)
            console.log("Signup successful:", response.data);
        } catch (error) {
            // Handle errors (e.g., display an error message)
            console.error("Signup error:", error);
        } finally {
            setIsLoading(false);
            // Navigate to the login page
            handleNavigateClick(navigate, "/dashboard", {}, false);
        }
    };

    return (
        <Box p={20}>
            <Stack minH={"100vh"} direction={{base: "column", md: "row"}}>
                <Flex p={8} flex={1} align={"center"} justify={"center"}>
                    <Stack spacing={4} w={"full"} maxW={"md"}>
                        <Heading fontSize={"3xl"}>Sign in NGML M/S</Heading>
                        <Heading fontSize={"2xl"}>Admin Login</Heading>
                        <FormControl id="email">
                            <FormLabel>Username</FormLabel>
                            <Input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input
                                value={formData.password}
                                onChange={handleInputChange}
                                name="password"
                                type="password"
                            />
                        </FormControl>
                        <Stack spacing={6}>
                            <Stack
                                direction={{base: "column", sm: "row"}}
                                align={"start"}
                                justify={"space-between"}
                            >
                                {/*<Checkbox>Remember me</Checkbox>*/}
                                <Text color={"blue.500"}>Forgot password?</Text>
                            </Stack>
                            <Button
                                onClick={handleSignIn}
                                colorScheme={"teal"}
                                variant={"solid"}
                                isLoading={isLoading}
                                loadingText="Signing in..."
                            >
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1} align={"center"}>
                    <Stack direction="column" m={10} align={"center"} p={10}>
                        <Image
                            boxSize="150px"
                            objectFit="contain"
                            src={logos.nnpc}
                            alt="NNPC Logo"
                        />
                        <Image
                            boxSize="150px"
                            objectFit="contain"
                            src={logos.ngml}
                            alt="NGML Logo"
                        />
                    </Stack>
                </Flex>
            </Stack>
        </Box>
    );
}
