export const handleNavigateClick = (navigate, path, stateData, replace) => {
  const navigateOptions = {
    state: stateData,
  }

  if (replace) {
    navigateOptions.replace = true
  }
  // Now you can navigate programmatically to other pages using navigate
  navigate(path, navigateOptions)
}

export const calculateAverageEntry = (items, properties) => {
  if (!Array.isArray(items) || items.length === 0) {
    throw new Error('Input must be a non-empty array')
  }

  const total = items.reduce((acc, item) => {
    properties.forEach((property) => {
      acc[property] = acc[property] + parseFloat(item[property] || 0.0)
    })
    return acc
  }, Object.fromEntries(properties.map((property) => [property, 0.0])))

  const count = items.length - 1 || 1 // Prevent division by zero

  const average = {}
  properties.forEach((property) => {
    average[property] = (total[property] / count).toFixed(2)
  })

  return average
}
