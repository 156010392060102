import { Radio, RadioGroup, Stack, TabPanel, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { Tab } from "semantic-ui-react";

export function FS201A() {
  const [checkedItems, setCheckedItems] = useState([false, false, false]);
  const toast = useToast();

  const handleCheck = (value, label) => {
    const index = value === "on-stream" ? 0 : value === "stand-by" ? 1 : 2;
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];

    const heaterStatus = newCheckedItems[index] === true ? "ON" : "OFF";

    toast({
      title: "NOTICE",
      description: `FS-201A  ${label} Mode turned ${heaterStatus}`,
      status: "error",
      position: "top-left",
      duration: 9000,
      isClosable: true,
    });

    setCheckedItems(newCheckedItems);
  };

  return (
    <Tab.Pane>
      <RadioGroup
        colorScheme="green"
        defaultValue={["on-stream", "stand-by", "maintenance-down"]}
      >
        <Stack spacing={[1, 5]} direction={["column"]}>
          <Radio
            value="on-stream"
            size="lg"
            onChange={() => handleCheck("on-stream", "ON STREAM")}
            isChecked={checkedItems[0]}
          >
            ON STREAM
          </Radio>
          <Radio
            value="stand-by"
            size="lg"
            onChange={() => handleCheck("stand-by", "STANDBY")}
            isChecked={checkedItems[1]}
          >
            STANDBY
          </Radio>
          <Radio
            value="maintenance-down"
            size="lg"
            onChange={() => handleCheck("maintenance-down", "MAINTENANCE/DOWN")}
            isChecked={checkedItems[2]}
          >
            MAINTENANCE/DOWN
          </Radio>
        </Stack>
      </RadioGroup>
    </Tab.Pane>
  );
}
