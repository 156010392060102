import { Form } from "semantic-ui-react";
import React, { useState, useEffect, useCallback } from "react";

import FormInput from "../../../forms/FormInput";

const OutageStart = () => {
  const [start, setStart] = useState("");

  const handleNameChange = (newName) => {
    setStart(newName);
  };

  const handleNameClear = () => {
    setStart("");
    // Additional clear logic if needed
    console.log("Name cleared");
  };
  const handleSubmit = useCallback(() => {
    handleNameClear();
  }, []);

  useEffect(() => {
    if (start === "") {
      handleSubmit();
    }
  }, [start, handleSubmit]);

  return (
    <div style={{ margin: "20px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <FormInput
            value={start}
            type={"time"}
            onChange={handleNameChange}
            onClear={handleNameClear}
            placeholder="Enter Start"
          />

          <Form.Button content="Submit" />
        </Form.Group>
      </Form>
    </div>
  );
};

export default OutageStart;
