import React from "react";

import {Button, Image, VStack} from '@chakra-ui/react'

export function AppButton({label, color, onclick, variant}) {
    return (
        <Button colorScheme={color} variant={variant}
                onClick={onclick}>
            {label}
        </Button>
    )
}



