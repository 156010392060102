export { FilterSeparator } from "./FilterSeparatorTab";
export { GasHeater } from "./GasHeaterTab";
// export { InstrumentGasSkid } from "./InstrumentGasSkidTab";
export { RegulationSkid } from "./RegulationSkidTab";
export { StationOutlet } from "./StationOutletTab";
// export { StationInlet} from './StationInletTab'
export { HTAEntry } from "./gas-heater/HT_A_Entry";
export { HTBEntry } from "./gas-heater/HT_B_Entry";
export { LineA } from "./regulation-skid/Line_A";
export { LineB } from "./regulation-skid/Line_B";

export const recordTimeFrames = {
  times: [
    "08:00",
    "10:00",
    "12:00",
    "14:00",
    "16:00",
    "18:00",
    "20:00",
    "22:00",
    "00:00",
    "02:00",
    "04:00",
    "06:00",
  ],
  label: "Select Time",
};
