import stationInletReducer from './stationInletReducer'
import stationOutletReducer from './stationOutletReducer'
import instrumentGasSkidReducer from './instrumentGasSkidReducer'
import filterSeparatorReducer from './filterSeparatorReducer'
import {combineReducers} from 'redux'
import regulationSkidLineAReducer from "./regulationSkidLineAReducer";
import regulationSkidLineBReducer from "./regulationSkidLineBReducer";
import gasHeaterHTAReducer from "./gasHeaterHTAReducer";
import gasHeaterHTBReducer from "./gasHeaterHTBReducer";

const rootReducer = combineReducers(
    {
        stationInletEntry: stationInletReducer,
        stationOutletEntry: stationOutletReducer,
        instrumentGasSkid: instrumentGasSkidReducer,
        filterSeparator: filterSeparatorReducer,
        regSkidLineA: regulationSkidLineAReducer,
        regSkidLineB: regulationSkidLineBReducer,
        gasHeaterHTA: gasHeaterHTAReducer,
        gasHeaterHTB: gasHeaterHTBReducer,
    },
)

export default rootReducer
