import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TabPanel } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  TableCaption,
  AbsoluteCenter,
  Box,
  VStack,
  TableContainer,
  useToast,
} from "@chakra-ui/react";
import {
  AppInput,
  AppHeading,
  TimeSelector,
  AppButton,
} from "../forms/AppForm";
import {
  addOutletData,
  updateOutletAverage,
} from "../../store/reducers/stationOutletReducer";

import { Tab } from "semantic-ui-react";

export function StationOutlet() {
  const dispatch = useDispatch();
  const outletData = useSelector(
    (state) => state.stationOutletEntry.outletEntries
  );

  const [selectedTime, setSelectedTime] = useState("");
  const [pressure, setPressure] = useState("");
  const [temperature, setTemperature] = useState("");
  const [inletHistoryData, setInletHistoryData] = useState([
    {
      timeStamp: "",
      time: "",
      pressure: "",
      temperature: "",
    },
  ]);

  const calculateAverage = (data) => {
    const total = data.reduce(
      (acc, item) => ({
        pressure: acc.pressure + parseFloat(item.pressure || 0.0),
        temperature: acc.temperature + parseFloat(item.temperature || 0.0),
      }),
      { pressure: 0.0, temperature: 0.0 }
    );

    const count = data.length - 1 || 1; // Prevent division by zero
    return {
      pressure: (total.pressure / count).toFixed(2),
      temperature: (total.temperature / count).toFixed(2),
    };
  };

  const [average, setAverage] = useState(calculateAverage(outletData));

  /// update abg calculation
  useEffect(() => {
    setAverage(calculateAverage(outletData));
  }, [outletData]);

  /// Update the averages
  useEffect(() => {
    dispatch(updateOutletAverage(average));
  }, [dispatch, average]);

  //   useEffect(() => {
  //     setAverage(calculateAverage(inletHistoryData));
  //   }, [inletHistoryData]);

  const toast = useToast();

  const recordTimeFrames = {
    times: [
      "08:00",
      "10:00",
      "12:00",
      "14:00",
      "16:00",
      "18:00",
      "20:00",
      "22:00",
      "00:00",
      "02:00",
      "04:00",
      "06:00",
    ],
    label: "Select Time",
  };

  const handleTime = (time) => {
    setSelectedTime(time);
  };

  const handlePressure = (pressure) => {
    setPressure(pressure);
  };

  const handleTemperature = (temp) => {
    setTemperature(temp);
  };

  function clearInputs() {
    setTemperature("");
    setPressure("");
    setSelectedTime("");
  }

  const handleSaveEntry = (e) => {
    e.preventDefault();
    if (selectedTime === "" || pressure === "" || temperature === "") {
      toast({
        title: "NOTICE",
        description: "You have to fill all details to save",
        status: "error",
        position: "top-left",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      const data = {
        timeStamp: Date(),
        time: selectedTime,
        pressure: pressure,
        temperature: temperature,
      };

      setInletHistoryData([...inletHistoryData, data]);
      dispatch(addOutletData(data)); /// update store
      clearInputs(); /// clear inputs
      //   console.log(data);
      //   console.log(inletHistoryData);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Tab.Pane>
      <AppHeading
        headerTitle={"Station outlet entry"}
        dividerLabel={"DATA ENTRY"}
      />
      <VStack spacing={4} align="stretch">
        <TableContainer>
          <Table variant="simple">
            <TableCaption>Station inlet periodic entry</TableCaption>
            <Thead>
              <Tr>
                <Th>TIME</Th>
                <Th>TEMP (C)</Th>
                <Th>PRESSURE (BARG)</Th>
                <Th isNumeric>ACTION</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <TimeSelector {...recordTimeFrames} onChange={handleTime} />
                </Td>
                <Td>
                  <AppInput
                    onClear={clearInputs}
                    placeholder={"Temp (C)"}
                    onChanged={handlePressure}
                    inputType={"number"}
                  />
                </Td>
                <Td>
                  <AppInput
                    placeholder={"pressure (Barg) "}
                    onChanged={handleTemperature}
                    inputType={"number"}
                  />
                </Td>

                <Td>
                  <AppButton
                    onclick={handleSaveEntry}
                    variant="solid"
                    label={"Save Entry"}
                    color={"teal"}
                  />{" "}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Box position="relative" padding="10">
          <Divider />
          <AbsoluteCenter bg="white" px="4">
            DATA RESULTS
          </AbsoluteCenter>
        </Box>
        <TableContainer>
          <Table variant="simple">
            <TableCaption>Station inlet periodic logs</TableCaption>
            <Thead>
              <Tr>
                <Th>TIME</Th>
                <Th>TEMP (C)</Th>
                <Th>PRESSURE (BARG)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {outletData.slice(1).map((data, index) => (
                <Tr key={index}>
                  <Td>{data.time}</Td>
                  <Td>{data.temperature}</Td>
                  <Td>{data.pressure}</Td>
                </Tr>
              ))}
              <Tr>
                <Td>Average 👉</Td>
                <Td>{average.temperature}</Td>
                <Td>{average.pressure}</Td>

                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Tab.Pane>
  );
}
